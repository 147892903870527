import React from "react";
// sthyled
import styled from "styled-components";
// video
import ReactPlayer from "react-player";

const VideoPlayer = ({ video }) => {
  return (
    <Player>
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={video}
          width="100%"
          height="100%"
          controls
          //light
          //playing
        />
      </div>
    </Player>
  );
};

const Player = styled.div`
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
export default VideoPlayer;
