import React from "react";
// styled
import styled from "styled-components";
// router
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
// animations
import { motion } from "framer-motion";
// bootstrap
import { Image } from "react-bootstrap";
import logo from "../img//logos/logo ta fotke.png";

const Nav = () => {
  const { pathname } = useLocation();
  return (
    <StyledNav>
      <Link id="logo" to="/">
        <Image src={logo} />
      </Link>
      <div className="parent my-5">
        <div className="mob-1">
          <ul>
            <li>
              <Link to="/">About Me</Link>
              <Line
                transition={{ duration: 0.75 }}
                initial={{ width: "0%" }}
                animate={{ width: pathname === "/" ? "50%" : "0%" }}
              />
            </li>
            {/* <li>
              <DropdownButton id="dropdown-basic-button" title="My Work">
                <Dropdown.Item>
                  <Link className="drop-item" to="/work">
                    My Work
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link className="drop-item" to="/work/design">
                    Design
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link className="drop-item" to="/work/photo">
                    Photo
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="drop-item">
                  <Link className="drop-item" to="/work/video">
                    Video
                  </Link>
                </Dropdown.Item>
              </DropdownButton>
              <Line
                transition={{ duration: 0.75 }}
                initial={{ width: "0%" }}
                animate={{ width: pathname === "/work" ? "50%" : "0%" }}
              />
            </li> */}
            <li>
              <Link to="/weddings">Weddings</Link>
              <Line
                transition={{ duration: 0.75 }}
                initial={{ width: "0%" }}
                animate={{ width: pathname === "/weddings" ? "50%" : "0%" }}
              />
            </li>
          </ul>
        </div>
        <div className="mob-2">
          <ul>
            {/* <li>
              <Link to="/podcast">Podcast</Link>
              <Line
                transition={{ duration: 0.75 }}
                initial={{ width: "0%" }}
                animate={{ width: pathname === "/podcast" ? "50%" : "0%" }}
              />
            </li> */}
            <li>
              <Link to="/studio">Studio</Link>
              <Line
                transition={{ duration: 0.75 }}
                initial={{ width: "0%" }}
                animate={{ width: pathname === "/studio" ? "50%" : "0%" }}
              />
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
              <Line
                transition={{ duration: 0.75 }}
                initial={{ width: "0%" }}
                animate={{ width: pathname === "/gallery" ? "50%" : "0%" }}
              />
            </li>
            {/* <li>
              <Link to="/gallery">Blog</Link>
              <Line
                transition={{ duration: 0.75 }}
                initial={{ width: "0%" }}
                animate={{ width: pathname === "/gallery" ? "50%" : "0%" }}
              />
            </li> */}
          </ul>
        </div>
      </div>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  height: 12vh;
  position: relative;
  .parent {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .mob1 {
    margin-right: -5rem;
  }
  #dropdown-basic-button {
    color: white;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 1.1rem;
  }
  .drop-item {
    color: black;
  }
  min-height: 10vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10rem;
  background: #282828;
  z-index: 10;
  a {
    color: white;
    text-decoration: none;
  }
  ul {
    display: flex;
    list-style: none;
  }
  #logo {
    img {
      height: 7rem;
      width: auto;
      margin-left: -4rem;
    }
  }
  li {
    padding-left: 6rem;
    white-space: nowrap;
    position: relative;
  }
  @media (max-width: 1300px) {
    height: auto;
    flex-direction: column;
    .parent {
      padding: 0;
      margin: 0;
    }
    #logo {
      display: inline-block;
      margin-top: 1rem;
    }
    ul {
      padding: 2rem;
      justify-content: space-around;
      width: 100%;
      li {
        padding: 1rem;
      }
    }
  }
  @media (max-width: 576px) {
    height: auto;
    .parent {
      display: block;
      margin: -2rem 0;
      .mob-1 {
        margin: -2rem 0;
      }
    }
    
    #logo {
    img {
      margin-left: 0rem;
    }
  }
`;

const Line = styled(motion.div)`
  height: 0.3rem;
  background: #ffd700;
  width: 0%;
  position: absolute;
  bottom: -80%;
  left: 50%;
  @media (max-width: 1300px) {
    left: 10%;
    top: 100%;
  }
`;

export default Nav;
