import React from "react";
// styled
import styled from "styled-components";
// bootstrap
import { Col, Row, Container } from "react-bootstrap";
// images
import etnoselo from "../img/logos/etnoselo.png";
import sunrise from "../img/logos/sunrise.png";
import koklub from "../img/logos/edin peko.png";
import meb from "../img/logos/mmeb.png";
import instabox from "../img/logos/instabox.png";
import lexy from "../img/logos/lexy.png";
import ellas from "../img/logos/ellas.png";
import bodax from "../img/logos/bodax.png";
import bost from "../img/logos/bost logo.png";
import flamingo from "../img/logos/flamingo.png";
import hangar from "../img/logos/hangar logo.png";
import kaf from "../img/logos/kaf.png";
import lib from "../img/logos/lib.png";
// import mega from "../img/logos/mega_horizontalni-crna-pozadina-kolor.png";
// animations
import { motion } from "framer-motion";
import { fade2 } from "../animation";
import { useScroll } from "./useScroll";
// quote
import quote from "../img/quote-svgrepo-com.svg";

const Comment = () => {
  const [element, controls] = useScroll();
  return (
    <StyledComment animate={controls} initial="hidden" ref={element}>
      <div className="testimonials mb-4">
        <h1 className="text-center mb-4">
          Check out some of <span>my work</span>.
        </h1>
        <Container>
          <Row className="justify-content-center align-items-center flex">
            <Col md className="text-center">
              <img
                src={etnoselo}
                className="img-fluid testimonials-img"
                alt=""
                variants={fade2}
              />
            </Col>
            <Col md className="quote text-center">
              <img src={quote} className="svg-icon" alt="etno selo"></img>
              <p variants={fade2} className="text-center">
                Svojim idejama donio je duh mladosti u Etno selo Stanišići.
                Fenomenalan fotograf, videograf i dizajner, odgovoran, nikad ne
                kasni sa materijalima, uvijek nasmijan i raspoložen. Sta više
                reći o ovom mladom momku?
              </p>
              <h5 className="text-center mb-4">
                Milena Stanišić, Menadžer Etno sela Stanišić.
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center flex">
            <Col md className="text-center">
              <img src={koklub} className="img-fluid testimonials-img" alt="" />
            </Col>
            <Col md className="quote text-center">
              <img src={quote} className="svg-icon" alt="quote"></img>
              <p className="text-center komentar">
                Na preporuku mog prijatelja sam počeo saradnju sa Lukom. Kao
                mladi dizajner koji je u usponu za pola godine saradnje se
                pokazao odlično.{<br />} Svoj rad je prilagodio našim prostorima
                i pored brzine i efikasnosti u izrađivanju sadržaja on drži i
                kvalitet. Stvar koja mi se kod njega najviše sviđa jeste
                odgovornost i poštovanje dogovora.
              </p>
              <h5 className="text-center mb-4">Edin Peko, KÖ Klub.</h5>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center flex">
            <Col md className="text-center">
              <img src={meb} className="img-fluid testimonials-img" alt="" />
            </Col>
            <Col md className="quote text-center">
              <img src={quote} className="svg-icon" alt="quote"></img>
              <p className="text-center komentar">
                Ozbiljan u svom poslu, precizan, tačan! Topla preporuka za sve
                koji žele na kvalitetan način da obilježe svoje važne trenutke.
              </p>
              <h5 className="text-center mb-4">
                Bojan Novokmet, Managment Event Box.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <h1 className="text-center my-4">
          Other <span>satisfied</span> clients.
        </h1>
        <div className="clients mt-5">
          <Container>
            <Row className="justify-content-md-center align-items-center pb-5">
              <Col md className="text-center">
                <img src={sunrise} />
              </Col>
              <Col md className="text-center">
                <img src={lib} />
              </Col>
              <Col md className="text-center">
                <img src={ellas} />
              </Col>
              <Col md className="text-center">
                <img src={flamingo} />
              </Col>
              <Col md className="text-center">
                <img src={bodax} />
              </Col>
            </Row>
            <Row>
              <Col md className="text-center">
                <img src={bost} />
              </Col>
              <Col md className="text-center">
                <img src={instabox} />
              </Col>
              <Col md className="text-center">
                <img src={hangar} />
              </Col>
              <Col md className="text-center">
                <img src={kaf} />
              </Col>
              <Col md className="text-center">
                <img src={lexy} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </StyledComment>
  );
};

const StyledComment = styled(motion.div)`
  font-family: "Inter", sans-serif;
  color: white;

  .clients {
    img {
      max-height: 200px;
      max-width: 100%;
    }
  }
  h1 {
    font-size: 4rem;
    font-weight: lighter;
  }
  h3,
  h5 {
    font-weight: lighter;
  }
  .testimonials-img {
    width: 60%;
    margin: auto;
  }
  .quote {
    box-shadow: rgba(255, 215, 0, 0.1) 0px 8px 24px,
      rgba(255, 215, 0, 0.1) 0px 16px 56px, rgba(255, 215, 0, 0.1) 0px 24px 80px;
    /* border: 5px solid #ffd700; */
    border-radius: 15px;
    padding: auto;
    margin-bottom: 2rem;
  }
  .quote p {
    font-weight: 400;
  }
  .text-center.komentar {
    font-size: 1.5rem;
    color: #f4f4f4;
  }
  .svg-icon {
    height: 70px;
  }
  .bottom {
    margin: 0px 0px 4rem 0rem;
  }
`;

export default Comment;
