// images
// design
import img0 from "./img/posters/P.png";
import img1 from "./img/posters/P1.png";
import img2 from "./img/posters/P2.jpg";
import img3 from "./img/posters/P3.jpg";
import img4 from "./img/posters/P4.jpg";
import img5 from "./img/posters/P5.jpg";
import img6 from "./img/posters/P6.png";
import img7 from "./img/posters/P7.png";
import img8 from "./img/posters/P8.png";
import img9 from "./img/posters/P9.png";
import img10 from "./img/posters/P10.png";
import img11 from "./img/posters/P11.png";
import img12 from "./img/posters/P12.jpg";
import img13 from "./img/posters/P13.png";
import img14 from "./img/posters/P14.png";
import img15 from "./img/posters/P15.jpg";
import img16 from "./img/posters/P16.jpg";
import img17 from "./img/posters/P17.png";
import img18 from "./img/posters/P18.jpg";
import img19 from "./img/posters/P19.jpg";
import img20 from "./img/posters/P20.jpg";
import img21 from "./img/posters/P21.jpg";
import img22 from "./img/posters/P22.png";
import img23 from "./img/posters/P23.png";
import img24 from "./img/posters/P24.png";
import img25 from "./img/posters/P25.png";
import img26 from "./img/posters/P26.jpg";
import img27 from "./img/posters/P27.jpg";
import img28 from "./img/posters/P28.png";
import img29 from "./img/posters/P29.png";
import img30 from "./img/posters/P36.png";
import img31 from "./img/posters/P37.jpg";
import img32 from "./img/posters/P38.jpg";
import img33 from "./img/posters/P39.jpg";
import img34 from "./img/posters/P40.png";
import img35 from "./img/posters/P41.jpg";
// photos
import simg1 from "./img/Sajt/S (1).jpg";
import simg2 from "./img/Sajt/S (3).jpg";
import simg3 from "./img/Sajt/S (4).jpg";
import simg4 from "./img/Sajt/S (5).jpg";
import simg5 from "./img/Sajt/S (6).jpg";
import simg6 from "./img/Sajt/S (7).jpg";
import simg7 from "./img/Sajt/S (8).jpg";
import simg8 from "./img/Sajt/S (9).jpg";
import simg9 from "./img/Sajt/S (10).jpg";
import simg10 from "./img/Sajt/S (11).jpg";
import simg11 from "./img/Sajt/S (12).jpg";
import simg12 from "./img/Sajt/S (13).jpg";
import simg13 from "./img/Sajt/S (14).jpg";
import simg14 from "./img/Sajt/S (15).jpg";
import simg15 from "./img/Sajt/S (16).jpg";
import simg16 from "./img/Sajt/S (17).jpg";
import simg17 from "./img/Sajt/S (18).jpg";
import simg18 from "./img/Sajt/S (19).jpg";
import simg19 from "./img/Sajt/S (20).jpg";
import simg20 from "./img/Sajt/S (21).jpg";
import simg21 from "./img/Sajt/S (22).jpg";
import simg22 from "./img/Sajt/S (23).jpg";
import simg23 from "./img/Sajt/S (24).jpg";
import simg24 from "./img/Sajt/S (25).jpg";
import simg25 from "./img/Sajt/S (26).jpg";
import simg26 from "./img/Sajt/S (27).jpg";
import simg27 from "./img/Sajt/S (28).jpg";
import simg28 from "./img/Sajt/S (29).jpg";
import simg29 from "./img/Sajt/S (30).jpg";
import simg30 from "./img/Sajt/S (31).jpg";
import simg31 from "./img/Sajt/S (2).jpg";
//
import simg32 from "./img/Sajt/S (32).jpg";
import simg33 from "./img/Sajt/S (33).jpg";
import simg34 from "./img/Sajt/S (34).jpg";
import simg35 from "./img/Sajt/S (35).jpg";
import simg36 from "./img/Sajt/S (36).jpg";
import simg37 from "./img/Sajt/S (37).jpg";
import simg38 from "./img/Sajt/S (38).jpg";
import simg39 from "./img/Sajt/S (39).jpg";
import simg40 from "./img/Sajt/S (40).jpg";
import simg61 from "./img/Sajt/S (41).jpg";
import simg41 from "./img/Sajt/S (42).jpg";
import simg42 from "./img/Sajt/S (43).jpg";
import simg43 from "./img/Sajt/S (44).jpg";
import simg44 from "./img/Sajt/S (45).jpg";
import simg45 from "./img/Sajt/S (46).jpg";
import simg46 from "./img/Sajt/S (47).jpg";
import simg47 from "./img/Sajt/S (48).jpg";
import simg48 from "./img/Sajt/S (49).jpg";
import simg49 from "./img/Sajt/S (50).jpg";
import simg50 from "./img/Sajt/S (51).jpg";
import simg51 from "./img/Sajt/S (52).jpg";
import simg52 from "./img/Sajt/S (53).jpg";
import simg53 from "./img/Sajt/S (54).jpg";
import simg54 from "./img/Sajt/S (55).jpg";
import simg55 from "./img/Sajt/S (56).jpg";
import simg56 from "./img/Sajt/S (57).jpg";
import simg57 from "./img/Sajt/S (58).jpg";
import simg58 from "./img/Sajt/S (59).jpg";
import simg59 from "./img/Sajt/S (60).jpg";
import simg60 from "./img/Sajt/S (61).jpg";
import simg62 from "./img/Sajt/S (62).jpg";
//
//
import simg63 from "./img/Sajt/S (63).jpg";
import simg64 from "./img/Sajt/S (64).jpg";
import simg65 from "./img/Sajt/S (65).jpg";
import simg66 from "./img/Sajt/S (66).jpg";
import simg67 from "./img/Sajt/S (67).jpg";
import simg68 from "./img/Sajt/S (68).jpg";
import simg69 from "./img/Sajt/S (69).jpg";
import simg70 from "./img/Sajt/S (70).jpg";
import simg71 from "./img/Sajt/S (71).jpg";
import simg72 from "./img/Sajt/S (72).jpg";
import simg73 from "./img/Sajt/S (73).jpg";
import simg74 from "./img/Sajt/S (74).jpg";
import simg75 from "./img/Sajt/S (75).jpg";
import simg76 from "./img/Sajt/S (76).jpg";
import simg77 from "./img/Sajt/S (77).jpg";
import simg78 from "./img/Sajt/S (78).jpg";
import simg79 from "./img/Sajt/S (79).jpg";
import simg80 from "./img/Sajt/S (80).jpg";
import simg81 from "./img/Sajt/S (81).jpg";
import simg82 from "./img/Sajt/S (82).jpg";
import simg83 from "./img/Sajt/S (83).jpg";
import simg84 from "./img/Sajt/S (84).jpg";
import simg85 from "./img/Sajt/S (85).jpg";
import simg86 from "./img/Sajt/S (86).jpg";
import simg87 from "./img/Sajt/S (87).jpg";
import simg88 from "./img/Sajt/S (88).jpg";
import simg89 from "./img/Sajt/S (89).jpg";
import simg90 from "./img/Sajt/S (90).jpg";
import simg91 from "./img/Sajt/S (91).jpg";
import simg92 from "./img/Sajt/S (92).jpg";
import simg93 from "./img/Sajt/S (93).jpg";
//
import simg94 from "./img/Sajt/S (94).jpg";
import simg95 from "./img/Sajt/S (95).jpg";
import simg96 from "./img/Sajt/S (96).jpg";
import simg97 from "./img/Sajt/S (97).jpg";
import simg98 from "./img/Sajt/S (98).jpg";
import simg99 from "./img/Sajt/S (99).jpg";
import simg100 from "./img/Sajt/S (100).jpg";
import simg101 from "./img/Sajt/S (101).jpg";
import simg102 from "./img/Sajt/S (102).jpg";
import simg103 from "./img/Sajt/S (103).jpg";
import simg104 from "./img/Sajt/S (104).jpg";
import simg105 from "./img/Sajt/S (105).jpg";
import simg106 from "./img/Sajt/S (106).jpg";
import simg107 from "./img/Sajt/S (107).jpg";
import simg108 from "./img/Sajt/S (108).jpg";
import simg109 from "./img/Sajt/S (109).jpg";
import simg110 from "./img/Sajt/S (110).jpg";
import simg111 from "./img/Sajt/S (111).jpg";
import simg112 from "./img/Sajt/S (112).jpg";
import simg113 from "./img/Sajt/S (113).jpg";
import simg114 from "./img/Sajt/S (114).jpg";
import simg115 from "./img/Sajt/S (115).jpg";
import simg116 from "./img/Sajt/S (116).jpg";
import simg117 from "./img/Sajt/S (117).jpg";
import simg118 from "./img/Sajt/S (118).jpg";
import simg119 from "./img/Sajt/S (119).jpg";
import simg120 from "./img/Sajt/S (120).jpg";
import simg121 from "./img/Sajt/S (121).jpg";
import simg122 from "./img/Sajt/S (122).jpg";
import simg123 from "./img/Sajt/S (123).jpg";
//
import simg124 from "./img/Sajt/S (124).jpg";
import simg125 from "./img/Sajt/S (125).jpg";
import simg126 from "./img/Sajt/S (126).jpg";
import simg127 from "./img/Sajt/S (127).jpg";
import simg128 from "./img/Sajt/S (128).jpg";
import simg129 from "./img/Sajt/S (129).jpg";
import simg130 from "./img/Sajt/S (130).jpg";
import simg131 from "./img/Sajt/S (131).jpg";
import simg132 from "./img/Sajt/S (132).jpg";
import simg133 from "./img/Sajt/S (133).jpg";
import simg134 from "./img/Sajt/S (134).jpg";
import simg135 from "./img/Sajt/S (135).jpg";
import simg136 from "./img/Sajt/S (136).jpg";
import simg137 from "./img/Sajt/S (137).jpg";
import simg138 from "./img/Sajt/S (138).jpg";
import simg139 from "./img/Sajt/S (139).jpg";
import simg140 from "./img/Sajt/S (140).jpg";
import simg141 from "./img/Sajt/S (141).jpg";
import simg142 from "./img/Sajt/S (142).jpg";
import simg143 from "./img/Sajt/S (143).jpg";
import simg144 from "./img/Sajt/S (144).jpg";
import simg145 from "./img/Sajt/S (145).jpg";
import simg146 from "./img/Sajt/S (146).jpg";
import simg147 from "./img/Sajt/S (147).jpg";
import simg148 from "./img/Sajt/S (148).jpg";
import simg149 from "./img/Sajt/S (149).jpg";
import simg150 from "./img/Sajt/S (150).jpg";
import simg151 from "./img/Sajt/S (151).jpg";
import simg152 from "./img/Sajt/S (152).jpg";
import simg153 from "./img/Sajt/S (153).jpg";

import simg154 from "./img/Sajt/S (154).jpg";
import simg155 from "./img/Sajt/S (155).jpg";
import simg156 from "./img/Sajt/S (156).jpg";
import simg157 from "./img/Sajt/S (157).jpg";
import simg158 from "./img/Sajt/S (158).jpg";
import simg159 from "./img/Sajt/S (159).jpg";
import simg160 from "./img/Sajt/S (160).jpg";
import simg161 from "./img/Sajt/S (161).jpg";
import simg162 from "./img/Sajt/S (162).jpg";
import simg163 from "./img/Sajt/S (163).jpg";
import simg164 from "./img/Sajt/S (164).jpg";
import simg165 from "./img/Sajt/S (165).jpg";
import simg166 from "./img/Sajt/S (166).jpg";
import simg167 from "./img/Sajt/S (167).jpg";
import simg168 from "./img/Sajt/S (168).jpg";
import simg169 from "./img/Sajt/S (169).jpg";
import simg170 from "./img/Sajt/S (170).jpg";
import simg171 from "./img/Sajt/S (171).jpg";
import simg172 from "./img/Sajt/S (172).jpg";
import simg173 from "./img/Sajt/S (173).jpg";
import simg174 from "./img/Sajt/S (174).jpg";
import simg175 from "./img/Sajt/S (175).jpg";
import simg176 from "./img/Sajt/S (176).jpg";
import simg177 from "./img/Sajt/S (177).jpg";
import simg178 from "./img/Sajt/S (178).jpg";
import simg179 from "./img/Sajt/S (179).jpg";
import simg180 from "./img/Sajt/S (180).jpg";
import simg181 from "./img/Sajt/S (181).jpg";
import simg182 from "./img/Sajt/S (182).jpg";
import simg183 from "./img/Sajt/S (183).jpg";
//
import simg184 from "./img/Sajt/S (184).jpg";
import simg185 from "./img/Sajt/S (185).jpg";
import simg186 from "./img/Sajt/S (186).jpg";
import simg187 from "./img/Sajt/S (187).jpg";
import simg188 from "./img/Sajt/S (188).jpg";
import simg189 from "./img/Sajt/S (189).jpg";
import simg190 from "./img/Sajt/S (190).jpg";
import simg191 from "./img/Sajt/S (191).jpg";
import simg192 from "./img/Sajt/S (192).jpg";
import simg193 from "./img/Sajt/S (193).jpg";
import simg194 from "./img/Sajt/S (194).jpg";
import simg195 from "./img/Sajt/S (195).jpg";
import simg196 from "./img/Sajt/S (196).jpg";
import simg197 from "./img/Sajt/S (197).jpg";
import simg198 from "./img/Sajt/S (198).jpg";
import simg199 from "./img/Sajt/S (199).jpg";
import simg200 from "./img/Sajt/S (200).jpg";
import simg201 from "./img/Sajt/S (201).jpg";
import simg202 from "./img/Sajt/S (202).jpg";
import simg203 from "./img/Sajt/S (203).jpg";
import simg204 from "./img/Sajt/S (204).jpg";
import simg205 from "./img/Sajt/S (205).jpg";
import simg206 from "./img/Sajt/S (206).jpg";
import simg207 from "./img/Sajt/S (207).jpg";
import simg208 from "./img/Sajt/S (208).jpg";
// banners and logos
import bimg1 from "./img/logos/L1.jpg";
import bimg2 from "./img/logos/L2.jpg";
import bimg3 from "./img/logos/L3.jpg";
import bimg4 from "./img/logos/L4.jpg";
import bimg5 from "./img/logos/L5.jpg";
import bimg6 from "./img/logos/L6.jpg";
import bimg7 from "./img/logos/L7.jpg";
import bimg8 from "./img/logos/L8.jpg";
import bimg9 from "./img/logos/L9.jpg";
import bimg10 from "./img/logos/L10.jpg";
import bimg11 from "./img/logos/L11.jpg";
import bimg12 from "./img/logos/L12.jpg";
// weddings
import wimg1 from "./img/weddings/w (1).jpg";
import wimg2 from "./img/weddings/w (2).jpg";
import wimg3 from "./img/weddings/w (3).jpg";
import wimg4 from "./img/weddings/w (4).jpg";
import wimg5 from "./img/weddings/w (5).jpg";
import wimg6 from "./img/weddings/w (6).jpg";
import wimg7 from "./img/weddings/w (7).jpg";
import wimg8 from "./img/weddings/w (8).jpg";
import wimg9 from "./img/weddings/w (9).jpg";
import wimg10 from "./img/weddings/w (10).jpg";
import wimg11 from "./img/weddings/w (11).jpg";
import wimg12 from "./img/weddings/w (12).jpg";
import wimg13 from "./img/weddings/w (13).jpg";
import wimg14 from "./img/weddings/w (14).jpg";
import wimg15 from "./img/weddings/w (15).jpg";
import wimg16 from "./img/weddings/w (16).jpg";
import wimg17 from "./img/weddings/w (17).jpg";
import wimg18 from "./img/weddings/w (18).jpg";
import wimg19 from "./img/weddings/w (19).jpg";
import wimg20 from "./img/weddings/w (20).jpg";
import wimg21 from "./img/weddings/w (21).jpg";
import wimg22 from "./img/weddings/w (22).jpg";
import wimg23 from "./img/weddings/w (23).jpg";
import wimg24 from "./img/weddings/w (24).jpg";
import wimg25 from "./img/weddings/w (25).jpg";
import wimg26 from "./img/weddings/w (26).jpg";
import wimg27 from "./img/weddings/w (27).jpg";
import wimg28 from "./img/weddings/w (28).jpg";
import wimg29 from "./img/weddings/w (29).jpg";
import wimg30 from "./img/weddings/w (30).jpg";
import wimg31 from "./img/weddings/w (31).jpg";
import wimg32 from "./img/weddings/w (32).jpg";
import wimg33 from "./img/weddings/w (33).jpg";
import wimg34 from "./img/weddings/w (34).jpg";
import wimg35 from "./img/weddings/w (35).jpg";
import wimg36 from "./img/weddings/w (36).jpg";
import wimg37 from "./img/weddings/w (37).jpg";
import wimg38 from "./img/weddings/w (38).jpg";
import wimg39 from "./img/weddings/w (39).jpg";
import wimg40 from "./img/weddings/w (40).jpg";
import wimg41 from "./img/weddings/w (41).jpg";
import wimg42 from "./img/weddings/w (42).jpg";
import wimg43 from "./img/weddings/w (43).jpg";
import wimg44 from "./img/weddings/w (44).jpg";

export const images0 = [
  {
    src: wimg18,
  },
  {
    src: wimg19,
  },
  {
    src: wimg20,
  },
  {
    src: wimg21,
  },
  {
    src: wimg22,
  },
  {
    src: wimg23,
  },
  {
    src: wimg24,
  },
  {
    src: wimg25,
  },
  {
    src: wimg26,
  },
  {
    src: wimg27,
  },
  {
    src: wimg28,
  },
  {
    src: wimg29,
  },
  {
    src: wimg30,
  },
  {
    src: wimg31,
  },
  {
    src: wimg32,
  },
  {
    src: wimg33,
  },
  {
    src: wimg34,
  },
  {
    src: wimg35,
  },
  {
    src: wimg36,
  },
  {
    src: wimg37,
  },
  {
    src: wimg38,
  },
  {
    src: wimg39,
  },
  {
    src: wimg40,
  },
  {
    src: wimg41,
  },
  {
    src: wimg42,
  },
  {
    src: wimg43,
  },
  {
    src: wimg44,
  },
  {
    src: wimg1,
  },
  {
    src: wimg2,
  },
  {
    src: wimg3,
  },
  {
    src: wimg4,
  },
  {
    src: wimg5,
  },
  {
    src: wimg6,
  },
  {
    src: wimg7,
  },
  {
    src: wimg8,
  },
  {
    src: wimg9,
  },
  {
    src: wimg10,
  },
  {
    src: wimg11,
  },
  {
    src: wimg12,
  },
  {
    src: wimg13,
  },
  {
    src: wimg14,
  },
  {
    src: wimg15,
  },
  {
    src: wimg16,
  },
  {
    src: wimg17,
  },
];

export const images1 = [
  {
    src: img0,
  },
  {
    src: img1,
  },
  {
    src: img2,
  },
  {
    src: img3,
  },
  {
    src: img4,
  },
  {
    src: img5,
  },
  {
    src: img32,
  },
  {
    src: img7,
  },
  {
    src: img8,
  },
  {
    src: img9,
  },
  {
    src: img10,
  },
  {
    src: img6,
  },
  {
    src: img12,
  },
  {
    src: img30,
  },
  {
    src: img14,
  },
  {
    src: img23,
  },
  {
    src: img16,
  },
  {
    src: img17,
  },
  {
    src: img18,
  },
  {
    src: img19,
  },
  {
    src: img20,
  },
  {
    src: img21,
  },
  {
    src: img22,
  },
  {
    src: img15,
  },
  {
    src: img24,
  },
  {
    src: img33,
  },
  {
    src: img26,
  },
  {
    src: img27,
  },
  {
    src: img28,
  },
  {
    src: img11,
  },
  {
    src: img29,
  },
  {
    src: img31,
  },
  {
    src: img35,
  },
  {
    src: img25,
  },
  {
    src: img34,
  },
  {
    src: img13,
  },
];

export const images2 = [
  {
    src: simg1,
  },
  {
    src: simg2,
  },
  {
    src: simg3,
  },
  {
    src: simg4,
  },
  {
    src: simg5,
  },
  {
    src: simg9,
  },
  {
    src: simg7,
  },
  {
    src: simg8,
  },
  {
    src: simg11,
  },
  {
    src: simg10,
  },
  {
    src: simg6,
  },
  {
    src: simg12,
  },
  {
    src: simg13,
  },
  {
    src: simg14,
  },
  {
    src: simg23,
  },
  {
    src: simg22,
  },
  {
    src: simg15,
  },
  {
    src: simg19,
  },
  {
    src: simg17,
  },
  {
    src: simg26,
  },
  {
    src: simg18,
  },
  {
    src: simg20,
  },
  {
    src: simg21,
  },
  {
    src: simg24,
  },
  {
    src: simg28,
  },
  {
    src: simg16,
  },
  {
    src: simg29,
  },
  {
    src: simg25,
  },
  {
    src: simg27,
  },
  {
    src: simg30,
  },
  {
    src: simg31,
  },
  //*************************************** */
  {
    src: simg32,
  },
  {
    src: simg33,
  },
  {
    src: simg34,
  },
  {
    src: simg35,
  },
  {
    src: simg36,
  },
  {
    src: simg37,
  },
  {
    src: simg38,
  },
  {
    src: simg39,
  },
  {
    src: simg40,
  },
  {
    src: simg41,
  },
  {
    src: simg42,
  },
  {
    src: simg43,
  },
  {
    src: simg44,
  },
  {
    src: simg45,
  },
  {
    src: simg46,
  },
  {
    src: simg47,
  },
  {
    src: simg48,
  },
  {
    src: simg49,
  },
  {
    src: simg50,
  },
  {
    src: simg51,
  },
  {
    src: simg52,
  },
  {
    src: simg53,
  },
  {
    src: simg54,
  },
  {
    src: simg55,
  },
  {
    src: simg56,
  },
  {
    src: simg57,
  },
  {
    src: simg58,
  },
  {
    src: simg59,
  },
  {
    src: simg60,
  },
  {
    src: simg61,
  },
  {
    src: simg62,
  },
  //*********************************** */
  {
    src: simg63,
  },
  {
    src: simg64,
  },
  {
    src: simg65,
  },
  {
    src: simg66,
  },
  {
    src: simg67,
  },
  {
    src: simg68,
  },
  {
    src: simg69,
  },
  {
    src: simg70,
  },
  {
    src: simg71,
  },
  {
    src: simg72,
  },
  {
    src: simg73,
  },
  {
    src: simg74,
  },
  {
    src: simg75,
  },
  {
    src: simg76,
  },
  {
    src: simg77,
  },
  {
    src: simg78,
  },
  {
    src: simg79,
  },
  {
    src: simg80,
  },
  {
    src: simg81,
  },
  {
    src: simg82,
  },
  {
    src: simg83,
  },
  {
    src: simg84,
  },
  {
    src: simg85,
  },
  {
    src: simg86,
  },
  {
    src: simg87,
  },
  {
    src: simg88,
  },
  {
    src: simg89,
  },
  {
    src: simg90,
  },
  {
    src: simg91,
  },
  {
    src: simg92,
  },
  {
    src: simg93,
  },
  //*********************************** */
  {
    src: simg94,
  },
  {
    src: simg95,
  },
  {
    src: simg96,
  },
  {
    src: simg97,
  },
  {
    src: simg98,
  },
  {
    src: simg99,
  },
  {
    src: simg100,
  },
  {
    src: simg101,
  },
  {
    src: simg102,
  },
  {
    src: simg103,
  },
  {
    src: simg104,
  },
  {
    src: simg105,
  },
  {
    src: simg106,
  },
  {
    src: simg107,
  },
  {
    src: simg108,
  },
  {
    src: simg109,
  },
  {
    src: simg110,
  },
  {
    src: simg111,
  },
  {
    src: simg112,
  },
  {
    src: simg113,
  },
  {
    src: simg114,
  },
  {
    src: simg115,
  },
  {
    src: simg116,
  },
  {
    src: simg117,
  },
  {
    src: simg118,
  },
  {
    src: simg119,
  },
  {
    src: simg120,
  },
  {
    src: simg121,
  },
  {
    src: simg122,
  },
  {
    src: simg123,
  },
  //*********************************** */
  {
    src: simg124,
  },
  {
    src: simg125,
  },
  {
    src: simg126,
  },
  {
    src: simg127,
  },
  {
    src: simg128,
  },
  {
    src: simg129,
  },
  {
    src: simg130,
  },
  {
    src: simg131,
  },
  {
    src: simg132,
  },
  {
    src: simg133,
  },
  {
    src: simg134,
  },
  {
    src: simg135,
  },
  {
    src: simg136,
  },
  {
    src: simg137,
  },
  {
    src: simg138,
  },
  {
    src: simg139,
  },
  {
    src: simg140,
  },
  {
    src: simg141,
  },
  {
    src: simg142,
  },
  {
    src: simg143,
  },
  {
    src: simg144,
  },
  {
    src: simg145,
  },
  {
    src: simg146,
  },
  {
    src: simg147,
  },
  {
    src: simg148,
  },
  {
    src: simg149,
  },
  {
    src: simg150,
  },
  {
    src: simg151,
  },
  {
    src: simg152,
  },
  {
    src: simg153,
  },
  //**** */
  {
    src: simg154,
  },
  {
    src: simg155,
  },
  {
    src: simg156,
  },
  {
    src: simg157,
  },
  {
    src: simg158,
  },
  {
    src: simg159,
  },
  {
    src: simg160,
  },
  {
    src: simg161,
  },
  {
    src: simg162,
  },
  {
    src: simg163,
  },
  {
    src: simg164,
  },
  {
    src: simg165,
  },
  {
    src: simg166,
  },
  {
    src: simg167,
  },
  {
    src: simg168,
  },
  {
    src: simg169,
  },
  {
    src: simg170,
  },
  {
    src: simg171,
  },
  {
    src: simg172,
  },
  {
    src: simg173,
  },
  {
    src: simg174,
  },
  {
    src: simg175,
  },
  {
    src: simg176,
  },
  {
    src: simg177,
  },
  {
    src: simg178,
  },
  {
    src: simg179,
  },
  {
    src: simg180,
  },
  {
    src: simg181,
  },
  {
    src: simg182,
  },
  {
    src: simg183,
  },
  //******* */
  {
    src: simg184,
  },
  {
    src: simg185,
  },
  {
    src: simg186,
  },
  {
    src: simg187,
  },
  {
    src: simg188,
  },
  {
    src: simg189,
  },
  {
    src: simg190,
  },
  {
    src: simg191,
  },
  {
    src: simg192,
  },
  {
    src: simg193,
  },
  {
    src: simg194,
  },
  {
    src: simg195,
  },
  {
    src: simg196,
  },
  {
    src: simg197,
  },
  {
    src: simg198,
  },
  {
    src: simg199,
  },
  {
    src: simg200,
  },
  {
    src: simg201,
  },
  {
    src: simg202,
  },
  {
    src: simg203,
  },
  {
    src: simg204,
  },
  {
    src: simg205,
  },
  {
    src: simg206,
  },
  {
    src: simg207,
  },
  {
    src: simg208,
  },
];

export const images3 = [
  {
    src: bimg1,
  },
  {
    src: bimg2,
  },
  {
    src: bimg5,
  },
  {
    src: bimg6,
  },
  {
    src: bimg7,
  },
  {
    src: bimg8,
  },
  {
    src: bimg9,
  },
  {
    src: bimg3,
  },
  {
    src: bimg4,
  },
  {
    src: bimg10,
  },
  {
    src: bimg11,
  },
  {
    src: bimg12,
  },
];
