import React from "react";
// styled
import styled from "styled-components";
// images
import instagram from "../img/instagram.svg";
import facebook from "../img/facebook.svg";
import youtube from "../img/youtube.svg";
import tiktok from "../img/tiktok copy.png";

const Footer = () => {
  return (
    <StyledFooter>
      <div className="footer">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4 footer-copyright text-center">
              <p>Milošević Photography ©2023. || All rights reserved.</p>
            </div>
            <div className="col-md-3 d-flex justify-content-center footer-a text-center">
              <a
                href="https://www.instagram.com/_milosevic_luka/"
                className="my-page"
              >
                <img src={instagram} alt="instagram" />
              </a>
              <a
                href="https://www.facebook.com/luka.milosevic01"
                className="my-page"
              >
                <img src={facebook} alt="facebook" />
              </a>
              <a
                href="https://youtube.com/channel/UC_-nNp4oA37yqeBYlbIqf_A"
                className="my-page"
              >
                <img src={youtube} alt="youtube" />
              </a>
              <a
                href="http://tiktok.com/@milosevicphotography"
                className="my-page"
              >
                <img src={tiktok} className="tt" alt="tiktok" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  .footer {
    background-color: #282828;
    color: white;
    font-size: 1rem;
    height: auto;
  }

  /* .footer-a {
    display: flex;
  } */

  .footer-a,
  .footer-copyright {
    margin-top: 2rem;
  }
  img {
    height: 5rem;
  }
  a.my-page {
    color: white;
    margin: 2rem;
    font-size: 1.1rem;
  }

  a.my-page:hover {
    transform: scale(1.1);
    transition: all 0.5s ease;
  }

  @media screen and (max-width: 767px) {
    .footer-a {
      margin: auto;
    }
  }
`;

export default Footer;
