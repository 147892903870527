import React from "react";
import styled from "styled-components";
import { About } from "../styles";
import Toggle from "./Toggle";
import { AnimateSharedLayout } from "framer-motion";
import ResponsiveGallery from "react-responsive-gallery";
// gallery
import { images2, images0 } from "../data";
// lazy load
import { LazyLoadComponent } from "react-lazy-load-image-component";
// bootstrap
import { Col, Row, Container } from "react-bootstrap";
// videos
import VideoPlayer from "./VideoPlayer";
import video1 from "../img/videi/V1.mp4";
import video2 from "../img/videi/V2.mp4";
import video3 from "../img/videi/V3.mp4";
import video4 from "../img/videi/V4.mp4";
import video5 from "../img/videi/V5.mp4";
import video6 from "../img/videi/V6.mp4";
import video7 from "../img/videi/V7.mp4";
import video8 from "../img/videi/V8.mp4";
import video9 from "../img/videi/V9.mp4";
import video10 from "../img/videi/V10.mp4";

const GalleryShow = () => {
  return (
    <StyledGalleryShow>
      <h2>
        Check out some of my <span>best work.</span>
      </h2>
      <AnimateSharedLayout>
        <Toggle title="Weddings.">
          <div className="answer">
            <LazyLoadComponent>
              <ResponsiveGallery images={images0} />
            </LazyLoadComponent>
          </div>
        </Toggle>
        {/* <Toggle title="Designs.">
          <div className="answer">
            <LazyLoadComponent>
              <ResponsiveGallery images={images1} />
            </LazyLoadComponent>
          </div>
        </Toggle>
        <Toggle title="Logo Design.">
          <div className="answer">
            <LazyLoadComponent visibleByDefaul>
              <ResponsiveGallery images={images3} />
            </LazyLoadComponent>
          </div> 
        </Toggle>*/}
        <Toggle title="Photos.">
          <div className="answer">
            <LazyLoadComponent visibleByDefaul>
              <ResponsiveGallery images={images2} />
            </LazyLoadComponent>
          </div>
        </Toggle>
        <Toggle title="Videos.">
          <div className="answer">
            <Container>
              <Row>
                <Col className="mt-4" md={6}>
                  <VideoPlayer video={video1} />
                </Col>
                <Col className="mt-4" md={6}>
                  <VideoPlayer video={video2} />
                </Col>
              </Row>
              <Row>
                <Col className="mt-4" md={6}>
                  <VideoPlayer video={video3} />
                </Col>
                <Col className="mt-4" md={6}>
                  <VideoPlayer video={video4} />
                </Col>
              </Row>
              <Row>
                <Col className="mt-4" md={6}>
                  <VideoPlayer video={video5} />
                </Col>
                <Col className="mt-4" md={6}>
                  <VideoPlayer video={video6} />
                </Col>
              </Row>
              <Row>
                <Col className="mt-4" md={6}>
                  <VideoPlayer video={video7} />
                </Col>
                <Col className="mt-4" md={6}>
                  <VideoPlayer video={video8} />
                </Col>
              </Row>
              <Row>
                <Col className="mt-4" md={6}>
                  <VideoPlayer video={video9} />
                </Col>
                <Col className="mt-4" md={6}>
                  <VideoPlayer video={video10} />
                </Col>
              </Row>
            </Container>
          </div>
        </Toggle>
      </AnimateSharedLayout>
    </StyledGalleryShow>
  );
};

const StyledGalleryShow = styled(About)`
  color: black;
  display: block;
  span {
    display: block;
  }
  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }
  .faq-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .question {
    padding: 3rem 0rem;
  }
  .answer {
    padding: 2rem 0rem;
    p {
      padding: 1rem 0rem;
    }
  }
`;

export default GalleryShow;
