import React from "react";
//Animations
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";
// styled
import styled from "styled-components";
// component
import GalleryShow from "../components/GalleryShow";
import Footer from "../components/Footer";

const ContactUs = () => {
  return (
    <div>
      <ContactStyle
        exit="exit"
        variants={pageAnimation}
        initial="hidden"
        animate="show"
        style={{ background: "#fff" }}
      >
        <GalleryShow />
      </ContactStyle>
      <Footer />
    </div>
  );
};

const ContactStyle = styled(motion.div)`
  padding: 5rem 10rem;
  color: #353535;
  min-height: 90vh;
  @media (max-width: 1500px) {
    padding: 2rem;
    font-size: 1rem;
  }
`;

export default ContactUs;
