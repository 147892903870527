import React from "react";
// bootstrap
import { Col, Row, Container } from "react-bootstrap";
import Image from "react-bootstrap/Image";
// styled
//import styled from "styled-components";
import { fade2 } from "../animation";
import img1 from "../img/w1.png";
import img2 from "../img/w2.png";
import img3 from "../img/w3.png";
import img4 from "../img/w4.png";
//Animations
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";
// components
import Footer from "../components/Footer";

const Weddings = () => {
  return (
    <motion.div
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <Container>
        <Row className="py-4">
          <Col md className="mb-4">
            <Image fluid src={img1} variants={fade2} />
          </Col>
          <Col md className="mb-4">
            <Image fluid src={img2} variants={fade2} />
          </Col>
          <Col md className="mb-4">
            <Image fluid src={img3} variants={fade2} />
          </Col>
          <Col md className="mb-4">
            <Image fluid src={img4} variants={fade2} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </motion.div>
  );
};

export default Weddings;
