import React from "react";
// images
import portfolio from "../img/lukaaaa.jpg";
// styles
import { About, Description, Image, Hide } from "../styles";
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade } from "../animation";
// components
import Wave from "./Wave";
import { Link } from "react-router-dom";
// lazy load image
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const AboutSection = () => {
  return (
    <About>
      <Description>
        <Wave />
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>I work to make</motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              your <span>dreams</span> come
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>true.</motion.h2>
          </Hide>
        </motion.div>
        <motion.p variants={fade}>
          Contact me for any photography, videography or design ideas that you
          have. I will try my best to make it as special as possible.
          <br />
        </motion.p>
        <div className="button">
          <Link to="/weddings">
            <motion.button className="m-2" variants={fade}>
              See gallery
            </motion.button>
            <motion.button variants={fade}>Weddings</motion.button>
          </Link>
        </div>
      </Description>
      <Image>
        <LazyLoadImage effect="blur" src={portfolio} />
      </Image>
    </About>
  );
};

//Styled Components

export default AboutSection;
