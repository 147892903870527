import React from "react";
//Animations
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";
import ScrollTop from "../components/ScrollTop";
// bootstrap
import { Col, Row, Container } from "react-bootstrap";
// styled
import styled from "styled-components";
import Footer from "../components/Footer";
import logo from "../img/logos/akro studio logo png.png";

const Weddings = () => {
  return (
    <div>
      <StyledStudio
        exit="exit"
        variants={pageAnimation}
        initial="hidden"
        animate="show"
      >
        <ScrollTop />
        <Container className="mt-6">
          <div className="studio-heading text-center my-3">
            <h1>
              STUDIO <span>AKRO</span>
            </h1>
            <h2 className="podnaslov">
              Vaš kreativni dom za fotografiju, video produkciju, dizajn i
              marketing.
            </h2>

            <p className="text-center">
              U srcu kreativnosti i inovacije, Studio <span>"Akro"</span> je vaš
              pouzdan partner za sve vizuelne i marketinške potrebe. Kroz našu
              strast prema umjetnosti i tehnološkoj ekspertizi, pružamo
              sveobuhvatne usluge koje će vaš brend istaći i ostaviti neizbrisiv
              utisak.
            </p>
          </div>
          <Row>
            <Col
              lg={{ order: "last" }}
              className="d-flex justify-content-center align-items-center"
            >
              <img src={logo} />
            </Col>
            <Col lg={{ order: "first" }} className="options">
              <ul>
                <li>
                  <span>Fotografija.</span>
                </li>
                <p>
                  U Studiu <span>"Akro"</span> vjerujemo da svaka slika ima
                  priču. Naš tim usmjeren je da uhvati svaki trenutak i da
                  prenese vašu priču kroz vizuelni jezik. Bez obzira da li je
                  riječ o portretima, proizvodnoj fotografiji ili događajima,
                  naša fotografija donosi estetiku, emociju i autentičnost.
                </p>
                <li>
                  <span>Video produkcija.</span>
                </li>
                <p>
                  Video je moćan alat za komunikaciju i povezivanje sa publikom.
                  U Studio <span>"Akro"</span> stvaramo visoko kvalitetne video
                  sadržaje koji će inspirisati, informisati i angažovati vašu
                  ciljnu grupu. Od video snimaka proslava i vjenčanja, reklamnih
                  video snimaka do korporativnih videa, mi donosimo vašu priču u
                  pokretu.
                </p>
                <li>
                  <span>Dizajn.</span>
                </li>
                <p>
                  Dizajn je srce vašeg brenda i prvi korak ka ostvarivanju
                  uspjeha. Naš tim dizajnera ima vizionarski pristup koji će vam
                  pomoći da se istaknete u gomili. Bilo da je riječ o grafičkom
                  dizajnu, brendiranju ili web dizajnu, Studio{" "}
                  <span>"Akro"</span> stvara estetski privlačne i funkcionalne
                  dizajne koji odražavaju vašjedinstveni identitet.
                </p>
                <li>
                  <span>Marketing.</span>
                </li>
                <p>
                  Vaša priča zaslužuje da bude viđena, a mi znamo kako da je
                  podjelimo sa svijetom. Naš tim razumije dinamiku digitalnog
                  marketinga i pruža strategije koje će povećati vidljivost
                  vašeg brenda. Kroz digitalni marketing, društvene medije, SEO
                  i još mnogo toga, Studio <span>"Akro"</span> će vaš brend
                  staviti pod svjetlost reflektora.
                </p>
              </ul>
            </Col>
          </Row>
          {/* <Row>
            <Col className="zasto-odabrati">
              <h1 className="zasto mb-5">
                Zašto odabrati Studio <span>Akro</span>?
              </h1>
              <ul>
                <li>
                  <strong>Strast prema kreativnosti:</strong>
                  <p>
                    Svaki projekat za nas je prilika da se izrazimo i stvorimo
                    nešto izuzetno.
                  </p>
                </li>
                <li>
                  <strong>Klijent-centričnost:</strong>
                  <p>
                    Vaša sreća i uspjeh su naš prioritet. Slušamo vaše potrebe i
                    prilagodimo naše usluge da odgovaraju vašim ciljevima.
                  </p>
                </li>
                <li>
                  <strong>Tehnološka ekselencija:</strong>
                  <p>
                    Koristimo najnovije tehnologije i alate kako bismo pružili
                    najbolje moguće rezultate.
                  </p>
                </li>
              </ul>
              <p>
                Budite dio naše priče u stvaranju vizuelnog svijeta koji
                ostavlja trajni utisak. Kontaktirajte nas danas i zajedno
                gradimo vašu viziju!
              </p>
            </Col>
          </Row> */}
        </Container>
      </StyledStudio>
      <Footer />
    </div>
  );
};

const StyledStudio = styled(motion.div)`
  background-color: #1b1b1b;

  .zasto {
    font-size: 3.5rem;
  }
  ul {
    font-size: 2.8rem;
  }
  .options li {
    margin: -1.4rem;
    list-style-type: "✔ ";
  }
  li::marker {
    color: #f4f4f4;
  }
  .zasto-odabrati li {
    margin: -1.4rem;
  }
  p,
  h1,
  h2,
  ul,
  li {
    color: #f4f4f4;
    font-family: "Inter", sans-serif;
  }
  h1 {
    font-size: 5rem;
  }
  h2 {
    font-size: 2rem;
  }
  img {
    max-width: 50rem;
  }
  span {
    color: #ffd700;
  }
  @media (max-width: 767px) {
    img {
      max-width: 29rem;
    }
    .options li {
      margin: 2rem;
    }
    .options p {
      margin-top: -3rem;
      margin-bottom: 0;
    }
  }
`;

export default Weddings;
