import { motion } from "framer-motion";
import React, { useState } from "react";

const Toggle = ({ children, title }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <motion.div layout className="question">
      <motion.div onClick={() => setToggle(!toggle)}>
        <motion.h4 layout>{title}</motion.h4>
        <motion.h5 layout>
          {toggle ? "Click to close." : "Click to see more."}
        </motion.h5>
      </motion.div>
      {toggle ? children : ""}
      <div className="faq-line"></div>
    </motion.div>
  );
};

export default Toggle;
